/** @jsx jsx */
import { css } from '@emotion/react'

export const mainSection = css`
  .iconsSection {
    display: flex;
    justify-content: center;
  }
  .studentsBrasilMobile {
    display: none;
    @media (max-width: 500px) {
      margin-top: 55px;
      display: flex;
      width: 100%;
    }
  }
  .iconsContainer {
    margin: 0 281px;
    @media (min-width: 500px) and (max-width: 850px) {
      max-width: 100%;
      margin: 0;
      padding: 0;
      width: 100%;
    }
    @media (max-width: 850px) {
      margin: 0;
    }
    @media (min-width: 850px) {
      display: flex;
      justify-content: center;
    }
  }
`
export const styles = css`
  margin-top: 60px;
  @media (max-width: 768px) {
    margin-top: 0px;
  }
  .title {
    width: 800px;
    color: #212121;
    font-weight: 700;
    font-size: 40px;
    margin-bottom: 24px;
    span {
      color: #4766a8;
    }

    @media (max-width: 500px) {
      width: 342px;
      font-size: 24px;
    }
    br {
      display: none;
    }
    @media (min-width: 500px) and (max-width: 850px) {
      br {
        display: flex;
      }

      width: 580px;
    }
  }
  .textOne {
    color: #212121;
    line-height: 150%;
    font-size: 18px;
    width: 853px;
    font-weight: 400;
    @media (max-width: 500px) {
      width: 100%;
      font-size: 16px;
    }
    @media (min-width: 500px) and (max-width: 850px) {
      width: 470px;
      margin-bottom: 88px;
    }
  }
  .textTwo {
    color: #212121;
    line-height: 150%;
    font-size: 18px;
    width: 853px;
    font-weight: bold;
    @media (max-width: 500px) {
      margin-top: 40px;
      width: 100%;
      font-size: 20px;
      font-weight: 600;
    }
    @media (min-width: 500px) and (max-width: 850px) {
      width: 515px;
      margin-bottom: 72px;
    }
  }
`

export const numberContainer = css`
  margin-top: 80px;
  display: grid;
  grid-template-areas:
    'div1 div2 div3'
    'div4 div5 div5';
  justify-items: center;
  justify-content: center;
  gap: 80px;
  @media (max-width: 500px) {
    gap: 48px;
  }
  
  .numbers {
    width: 285px;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #000617;
    @media (max-width: 500px) {
      font-size: 20px;
      line-height: 150%;
    }

    > span {
      font-weight: 800;
      font-size: 64px;
      line-height: 38px;
      letter-spacing: 3.2px;
      color: transparent;
      -webkit-text-stroke: 2px #4766a8;
    }

    > p {
      margin-top: 32px;
    }
  }

  .div1 {
    grid-area: div1;
  }

  .div2 {
    grid-area: div2;
  }

  .div3 {
    grid-area: div3;
  }

  .div4 {
    grid-area: div4;
    @media (min-width: 850px) {
      position: relative;
      left: 90px;
    }
  }

  .div5 {
    grid-area: div5;
    @media (min-width: 850px) {
      position: relative;
      left: 90px;
    }
    @media (max-width: 850px) {
      grid-area: initial;
    }
  }

  @media (max-width: 850px) {
    grid-template-areas:
      'div1 div2'
      'div3 div4'
      'div5 div5';
  }

  @media (max-width: 500px) {
    grid-template-areas:
      'div1'
      'div2'
      'div3'
      'div4'
      'div5';
  }
`

export const info = css`
  br {
    display: none;
    @media (max-width: 500px) {
      display: inline;
    }
  }
  span {
    @media (max-width: 500px) {
      color: #212121;
    }
  }
  h3 {
    margin-top: 111px;
    color: #4766a8;
    border-bottom: 1px solid rgba(14, 20, 34, 0.2);
    padding-bottom: 11px;
    @media (max-width: 500px) {
      font-weight: 700;
    }
    @media (min-width: 500px) and (max-width: 850px) {
      border-bottom: 1px solid #4766a8;
      font-weight: 700;
      font-size: 24px;
      margin-bottom: 21px;
      width: 485px;
    }
  }
  p {
    width: 797px;
    @media (max-width: 500px) {
      width: 100%;
    }
  }

  .infoOne {
    font-size: 16px;
    @media (min-width: 500px) and (max-width: 850px) {
      width: 550px;
    }
  }
  .infoTwo {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    @media (max-width: 500px) {
      font-weight: 400;
    }
    @media (min-width: 500px) and (max-width: 850px) {
      width: 550px;
    }
  }
`

export const icons = css`
  display: flex;
  flex-wrap: wrap;
  gap: 38px;
  justify-content: center;
  margin-top: 80px;
  margin-bottom: 112px;
  @media (min-width: 500px) and (max-width: 850px) {
    gap: 20px
  }
  @media (min-width: 850px) {
      width: 838px;
    }
  .Iconcontainer {
    background-color: #eef1f7;
    border-radius: 24px;
    display: grid;
    font-size: 24px;
    height: 300px;
    justify-content: center;
    line-height: 36px;
    width: 400px;
    img {
      margin-top: 35px;
      justify-self: center;
    }
    p {
      text-align: center;
      width: 320px;
      font-weight: 600;
      @media (max-width: 500px) {
        font-size: 20px;
        
        line-height: 150%;
        width: 275px;
      }
      @media (min-width: 500px) and (max-width: 850px) {
        width: 265px;
        font-size: 20px;
      }
    }
    @media (max-width: 850px) {
      background-color: transparent;
    }
  }
`

export const studentsSection = css`
  @media (max-width: 500px) {
    display: none;
  }
  display: flex;
  align-items: center;
  .text {
    margin-right: 150px;
    @media (min-width: 500px) and (max-width: 850px) {
      width: 348px;
      margin-right: 18px;
    }
    h2 {
      color: #4766a8;
      font-size: 28px;
      font-weight: 700;
      line-height: 120%;
      width: 578px;
      @media (min-width: 500px) and (max-width: 850px) {
        width: 360px;
        font-weight: 600;
        line-height: 150%;
        font-size: 20px;
      }
    }
    p {
      color: #212121;
      font-size: 18px;
      line-height: 150%;
      margin-top: 32px;
      margin-bottom: 64px;
      @media (min-width: 500px) and (max-width: 850px) {
        margin-bottom: 0;
      }
    }
  }
  img {
    width: 474px;
    height: 595px;
    @media (min-width: 500px) and (max-width: 850px) {
      width: 296px;
      height: 380px;
    }
  }
  .linkButton {
    @media (min-width: 500px) and (max-width: 850px) {
      display: none;
    }
  }
`
