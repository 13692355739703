import React from "react";
import Footer from "@/components/footer";
import Layout from "@/components/layout";
import MenuContextProvider from "@/context/menu-context";
import SearchContextProvider from "@/context/search-context";
import HeaderOne from "@/components/header-one";
import oQueFazemosBanner from '../assets/images/LP-vertere/banner-oque-fazemos.png'
import oQueFazemosBannerMobile from '../assets/images/LP-vertere/banner-oque-fazemos-mobile.png'
import oQueFazemosBannerTablet from '../assets/images/LP-vertere/banner-oque-fazemos-tablet.png'
import PageBanner from '../components/page-banner';
import WhatWeShare from '../components/what-we-share';

const OqueFazemos = () => {
  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <Layout PageTitle="O que Fazemos">
          <HeaderOne />
          <PageBanner backgroundImage={oQueFazemosBanner} backgroundImageMobile={oQueFazemosBannerMobile} backgroundImageTablet={oQueFazemosBannerTablet} pageName='O QUE FAZEMOS'  />
          <WhatWeShare></WhatWeShare>
          <Footer />
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default OqueFazemos;
