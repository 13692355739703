import React from 'react'
import { LinkCustom } from '../assets/styles/LinkButton.styles'
const LinkButton = ({ label, url, fontSize, fontWeight, marginTop, marginBottom, target }) => {
  return (
    <LinkCustom
      style={{ fontSize: `${fontSize}px`, fontWeight: fontWeight, marginTop: marginTop, marginBottom: marginBottom }}
      href={url}
      target={target}
    >
      {label}
      <svg
        width='11'
        height='12'
        viewBox='0 0 11 12'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M2.2289 0.349609H9.88199C10.0774 0.349609 10.2647 0.429964 10.4029 0.572995C10.5409 0.716027 10.6186 0.910015 10.6186 1.11229V9.03585H9.14541V2.95342L1.043 11.343L0 10.2646L8.10241 1.87498H2.22817L2.2289 0.349609Z'
          fill='#0C79FE'
        />
      </svg>
    </LinkCustom>
  )
}

export default LinkButton
