/** @jsx jsx */
import { jsx } from '@emotion/react'

import {
  icons,
  info,
  mainSection,
  numberContainer,
  studentsSection,
  styles,
} from '../assets/styles/WhatWeShare.styles'
import people from '../assets/images/LP-vertere/people.svg'
import leef from '../assets/images/LP-vertere/leef.svg'
import trending from '../assets/images/LP-vertere/trending.svg'
import globo from '../assets/images/LP-vertere/globo.svg'

import studentsBrasil from '../assets/images/LP-vertere/students-brazil.png'
import studentsBrasilMobile from '../assets/images/LP-vertere/students-brazil-mobile.png'

import { Container } from 'react-bootstrap'
import LinkButton from './linkButton'
import { navHistory } from '../assets/styles/SectionTitle.styles'
import NavPageHistory from './navPageHistory'

const WhatWeShare = () => {
  return (
    <section
      css={mainSection}
      style={{ paddingTop: '1px', paddingBottom: '120px' }}
    >
      <div css={navHistory}>
        <NavPageHistory
          display='flex'
          color='black'
          pageName='O QUE FAZEMOS'
        ></NavPageHistory>
      </div>
      <Container css={styles}>
        <div data-aos="fade-right" className='titleContent'>
          <h1 className='title'>
            Compartilhamos <br /> conhecimento para acelerar a
            <span> transformação social</span>
          </h1>
          <p className='textOne'>
            Para nós, o impacto social significa compartilhar conhecimento para
            acelerar a transformação social em todo o mundo, fortalecendo o
            interesse pelo mundo, a autonomia e o protagonismo dos jovens e o
            estabelecimento de relações de fraternidade, auto-organização e
            redes.
          </p>
          <p className='textTwo'>
            Com o objetivo de promover a inclusão e o desenvolvimento
            educacional, atuamos nas seguintes frentes:
          </p>
        </div>
      </Container>
      <Container data-aos="fade-left" css={numberContainer}>
        <div className='numbers div1'>
          <span>01</span>
          <p>Promoção de competições olímpicas isônomas e de largo alcance</p>
        </div>
        <div className='numbers div2'>
          <span>02</span>
          <p>Suporte jurídico, tecnológico e estratégico para as olimpíadas</p>
        </div>
        <div className='numbers div3'>
          <span>03</span>
          <p>Suporte jurídico, tecnológico e estratégico para as olimpíadas</p>
        </div>
        <div className='numbers div4'>
          <span>04</span>
          <p>Desenvolvimento de plataformas online e outras soluções tecnológicas para educação</p>
        </div>
        <div className='numbers div5'>
          <span>05</span>
          <p>Consultoria pedagógica para escolas, para o desenvolvimento de práticas baseadas em problemas</p>
        </div>
      </Container>
      <img className='studentsBrasilMobile' src={studentsBrasilMobile} alt='' />
      <Container data-aos="fade-right" css={info}>
        <h3>
          <span>Educação:</span> <br /> qualificação para o futuro
        </h3>
        <p className='infoOne'>
          Mais de 65 mil jovens foram impactados pelas práticas incubadas ou
          apoiadas pelo Instituto, com projetos inovadores que envolvem
          sustentabilidade, tecnologia, saúde, bem estar social, temáticas
          históricas e atuais, buscando criar uma juventude que impacte as
          próximas gerações por meio dos seus trabalhos.
        </p>
        <p className='infoTwo'>
          Nossas propostas e iniciativas já mudaram a vida de milhares de
          educadores e estudantes brasileiros. Desde que começamos a atuar:
        </p>
      </Container>
      <div data-aos="fade-left" className='iconsContainer'>
        <div css={icons}>
          <div className='Iconcontainer'>
            <img src={people} alt='Icone de pessoas' />
            <p>Impactamos estudantes dos 26 estados e do Distrito Federal</p>
          </div>
          <div className='Iconcontainer'>
            <img src={leef} alt='Icone folha de árvore' />
            <p>
              Adotamos critérios ESG em nossos processos e tomadas de decisão
            </p>
          </div>
          <div className='Iconcontainer'>
            <img src={trending} alt='Icone maximizar impacto' />
            <p>
              Maximizamos o impacto social a partir de ações e projetos
              desenvolvidos por alunos ao redor do mundo.
            </p>
          </div>

          <div className='Iconcontainer'>
            <img src={globo} alt='Incone globo terrestre' />
            <p>
              Temos alcance internacional em países como Paraguai, Argentina,
              Rússia, Coréia e Canadá
            </p>
          </div>
        </div>
      </div>
      <Container css={studentsSection}>
        <div data-aos="fade-right" className='text'>
          <h2>
            Acreditamos que é nosso papel contribuir para o desenvolvimento de
            uma sociedade mais justa, inclusiva e com oportunidades para
            todos(as)
          </h2>
          <p>
            Nosso papel é mostrar para instituições de ensino, poder público e
            iniciativa privada que a educação e o futuro da nossa juventude
            devem ser prioridades urgentes da nossa sociedade nos dias de hoje
          </p>

          <div className='linkButton'>
            <LinkButton
              label='Conheça Nossas Olimpíadas'
              url='/nossas-olimpiadas'
            ></LinkButton>
          </div>
        </div>
        <div data-aos="fade-left">
          <img
            src={studentsBrasil}
            alt='Estudantes segurando a bandeira do Brasil'
          />
        </div>
      </Container>
    </section>
  )
}

export default WhatWeShare
