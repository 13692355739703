/**@jsx jsx */
import { css } from '@emotion/css'
import styled from '@emotion/styled'

export const LinkCustom = styled.a`
  margin-top: 24px;
  font-size: 16px;
  color: #0c79fe;
  font-weight: 600;
  width: fit-content;
  position: relative;
  display: inline-block;
  text-decoration: none;
  :after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 0;
    height: 2px;
    background-color: #4766a8;
    transition: width 0.3s ease;
  }
  :visited { 
    color: #0c79fe;
    path {
        fill: #0c79fe;
      }
  }
  :hover {
    color: #4766a8;
    path {
        fill: #4766a8;
      }
  }
  :hover:after {
    width: 100%;
  }
  > svg {
    align-self: center;
    margin-left: 4px;
    :hover {
      
    }
  }
`
